
import { defineComponent, ref, watchEffect } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import mautic from "@/core/plugins/mautic";
import "yup-phone-lite";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage
  },

  mounted() {
    //mautic start
    mautic.initialize("https://hello.futuregg.com/mtc.js");
    mautic.pageView({}, false);
  },

  setup() {
    const store = useStore();
    const router = useRouter();

    const { t, te } = useI18n();
    const i18n = useI18n();

    const submitButton = ref<HTMLElement | null>(null);
    const countryCode = ref('');

    const translate = text => {
      return te(text) ? t(text) : text;
    };

    i18n.locale.value = localStorage.getItem("lang")
    ? (localStorage.getItem("lang") as string)
    : "en";

    const setLang = lang => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = lang => {
      return i18n.locale.value === lang;
    };

    const registration = Yup.object().shape({
      name: Yup.string().required(
        translate("name") + " " + translate("isRequired")
      ),
      surname: Yup.string().required(
        translate("lastName") + " " + translate("isRequired")
      ),
      phone: Yup.string().phone(undefined, translate("phoneNumber")),
      toc: Yup.bool().required(
        translate("Accept") + " " + translate("isRequired")
      ),
      email: Yup.string()
        .min(4)
        .required(translate("email") + " " + translate("isRequired"))
        .email(translate("email") + " " + translate("validEmail")),
      password: Yup.string()
        .minUppercase(
          1,
          translate("password") + " " + translate("minUppercase")
        )
        .minNumbers(1, translate("password") + " " + translate("minNumbers"))
        .minSymbols(1, translate("password") + " " + translate("minSymbols"))
        .min(8, translate("password") + " " + translate("min"))
        .required(translate("password") + " " + translate("isRequired")),
      cpassword: Yup.string()
        .required(translate("passwordConfirm") + " " + translate("isRequired"))
        .oneOf([Yup.ref("password"), null], translate("PasswordsMustMatchYup"))
    });

    const onSubmitRegister = values => {

      store.dispatch(Actions.LOGOUT);

      submitButton.value?.setAttribute("data-kt-indicator", "on");

      setTimeout(() => {
        store
          .dispatch(Actions.REGISTER, values)
          .then(() => {
            router.push({ name: "dashboard" });
          })
          .catch(() => {
            Swal.fire({
              text: translate("alreadyInUse"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: translate("tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger"
              }
            });
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    watchEffect(() => {
      /* const phoneValue = values.phone;
      const phone = parsePhoneNumberFromString(phoneValue); */
      // countryCode.value = phone?.country ?? ''
    })

    return {
      registration,
      onSubmitRegister,
      submitButton,
      setLang,
      currentLanguage,
      translate,
      countryCode
    };
  }
});
